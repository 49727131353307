import { VectorImages } from "components";
import { Box, Stack, Text, Title } from "@mantine/core";
import { StepsComponent } from "./components";
import { FormProvider, StepProvider } from "./providers";
import { useEffect } from "react";
import { useAuth } from "providers";

export function RequestPickupPage() {
  const { apiKeyLogin } = useAuth();

  useEffect(() => apiKeyLogin(), [apiKeyLogin]);

  return (
    <Box
      sx={(theme) => ({
        minHeight: "100%",
        backgroundColor: theme.colors.gray[0],
      })}
    >
      <Stack p="xl" spacing={"md"}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <VectorImages.Logo />
        </Box>
        <Stack spacing={0}>
          <Title>Form Permintaan Pengambilan Barang</Title>
          <Text fz="sm" c="dimmed">
            Mazuta akan mengambil barang sesuai dengan permintaan dari customer.
            Ingat, form ini hanya bisa diisi oleh serial number yang telah
            terdaftar didalam sistem.
          </Text>
        </Stack>
        <FormProvider>
          <StepProvider maxSteps={7}>
            <StepsComponent />
          </StepProvider>
        </FormProvider>
      </Stack>
    </Box>
  );
}
