import { Button } from "@mantine/core";

import { useLoginProvider } from "../../providers";
import { useAuth } from "../../../../providers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoaderComponent } from "components";

export function LoginButtonComponent() {
  const navigate = useNavigate();
  const { email, password } = useLoginProvider();
  const { accessToken, login } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleClick() {
    if (!email) return;
    if (!password) return;

    try {
      setIsLoading(true);
      await login(email, password);
      navigate("/apps", { replace: true });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (accessToken) navigate("/apps", { replace: true });
  }, [accessToken, navigate]);

  return (
    <Button
      onClick={handleClick}
      leftIcon={isLoading && <LoaderComponent size={13} />}
      disabled={isLoading}
    >
      Login
    </Button>
  );
}
