import { TextInput } from "@mantine/core";
import { TextInputComponentProps } from "./types";

export function TextInputComponent(props: TextInputComponentProps) {
  const {
    label,
    required,
    placeholder,
    value,
    disabled,
    radius,
    rightSection,
    rightSectionWidth,
    autoFocus,
    icon,
    error,
    onChange,
  } = props;

  return (
    <TextInput
      placeholder={placeholder ?? "Type here..."}
      label={label}
      required={required}
      value={value}
      onChange={onChange}
      disabled={disabled}
      rightSectionWidth={rightSectionWidth}
      rightSection={rightSection}
      radius={radius}
      autoFocus={autoFocus}
      icon={icon}
      error={error ? `${error}` : undefined}
    />
  );
}
