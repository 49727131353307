import { MultiSelect } from "@mantine/core";
import { useFormProvider } from "../../../../providers";
import { SparePartModel } from "models";

export function PartsComponent() {
  const { parts } = useFormProvider();

  function generateData(parts: SparePartModel[]) {
    return parts.map((part) => ({
      value: part.id,
      label: part.name,
    }));
  }

  function generateValue(parts: SparePartModel[]) {
    return parts.map((part) => part.id);
  }

  return (
    <MultiSelect
      label="Kelengkapan"
      value={generateValue(parts)}
      data={generateData(parts)}
      disabled
    />
  );
}
