import { useRoutes } from "react-router-dom";

import { Navigate } from "react-router-dom";
import { PrivateRouteComponent } from "components";
import { PrivateRoute } from "./private";
import { LoginPage, NotFoundPage, RequestPickupPage } from "pages";

export const PublicRoute = () => {
  return useRoutes([
    {
      path: "/oops",
      element: <NotFoundPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/request-pickup",
      element: <RequestPickupPage />,
    },
    {
      path: "/",
      element: <PrivateRouteComponent />,
      children: PrivateRoute(),
    },
    {
      path: "*",
      element: <Navigate to="oops" replace={true} />,
    },
  ]);
};
