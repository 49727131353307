import { Divider, Stack } from "@mantine/core";
import { BackButtonComponent, ReviewForm } from "..";

export function ReviewStep() {
  return (
    <Stack>
      <ReviewForm.SerialNumber />
      <ReviewForm.Device />
      <ReviewForm.Brand />
      <ReviewForm.Parts />
      <ReviewForm.Complaint />
      <Divider />
      <ReviewForm.ServiceCenter />
      <ReviewForm.ServiceCenterAddress />
      <Divider />
      <ReviewForm.Name />
      <ReviewForm.PhoneNumber />
      <ReviewForm.PickupLocation />
      <ReviewForm.Address />
      <Divider />
      <ReviewForm.Courier />
      <ReviewForm.CourierService />
      <ReviewForm.DeliveryFee />
      <ReviewForm.InsuranceFee />
      <BackButtonComponent />
      <ReviewForm.SubmitButton />
    </Stack>
  );
}
