import validator from "validator";

import { useCallback, useState } from "react";
import {
  useFormProvider,
  useStepProvider,
  useSenderInformationProvider,
} from "../../../../providers";

import { Button } from "@mantine/core";

export function SubmitButtonComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const { nextStep } = useStepProvider();
  const { setName, setPhoneNumber, setOriginLocation, setAddress } =
    useFormProvider();
  const { name, phoneNumber, originLocation, address } =
    useSenderInformationProvider();

  const ableToNextStep = useCallback(() => {
    if (validator.isEmpty(name)) return false;
    if (validator.isEmpty(phoneNumber)) return false;
    if (!originLocation) return false;
    if (validator.isEmpty(address)) return false;
    return true;
  }, [name, phoneNumber, originLocation, address]);

  async function handleClick() {
    try {
      setIsLoading(true);
      if (!ableToNextStep()) throw new Error(`Unable to go to next step`);

      // Save the data to formProvider
      setName(name);
      setPhoneNumber(phoneNumber);
      setOriginLocation(originLocation);
      setAddress(address);

      // Do a bit of a second delay for UI/UX
      const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));
      await delay();

      // Go to next step
      nextStep();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      disabled={!ableToNextStep()}
      loading={isLoading}
      onClick={handleClick}
    >
      Selanjutnya
    </Button>
  );
}
