import { Textarea } from "@mantine/core";
import { useDeliveryDestinationProvider } from "../../../../providers";

export function AddressComponent() {
  const { serviceCenter } = useDeliveryDestinationProvider();

  return (
    <Textarea
      label="Alamat"
      minRows={5}
      value={(serviceCenter && serviceCenter.logisticLocation.address) ?? ""}
      disabled
    />
  );
}
