import { Textarea } from "@mantine/core";
import { useFormProvider } from "../../../../providers";

export function ServiceCenterAddressComponent() {
  const { serviceCenter } = useFormProvider();

  return (
    <Textarea
      label="Alamat Service Center"
      minRows={5}
      value={serviceCenter && serviceCenter.logisticLocation.address}
      disabled
    />
  );
}
