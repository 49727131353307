import lodash from "lodash";
import { Button } from "@mantine/core";
import { loadingComplete, showLoading } from "utils/loader";
import { net } from "utils/net";

import { useAuth } from "providers";
import { useState } from "react";
import { useFormProvider } from "../../providers";

export function RegisterButtonComponent() {
  const { isClean, data, dispatch } = useFormProvider();
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleClick() {
    if (!accessToken) return;
    if (!data.invoice) return;
    if (!data.barcode) return;
    if (!data.device) return;
    if (!data.serialNumber) return;

    try {
      setIsLoading(true);
      showLoading("warranty-registration");

      const relUrl = `/sale-transactions`;
      const headers = { "Content-Type": "multipart/form-data" };

      const body = new FormData();
      body.append("invoice", data.invoice);
      body.append("serial_number", data.barcode);
      body.append("device", data.device);
      body.append("sn", data.serialNumber);

      const netInstance = await net(accessToken);
      const res = await netInstance.post(relUrl, body, { headers });
      const errorText = lodash(res).get("data.error_text");
      if (errorText) throw new Error(errorText);

      dispatch({ type: "CLEAN" });

      loadingComplete("warranty-registration", false);
    } catch (err: any) {
      const errMessage = lodash(err).get("message");
      loadingComplete("warranty-registration", true, errMessage);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button disabled={!isClean || isLoading} onClick={handleClick}>
      Registrasi
    </Button>
  );
}
