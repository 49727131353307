import lodash from "lodash";
import { InternalLocationModel } from "models";

import useSWR from "swr";
import { useAuth } from "providers";
import { fetcher } from "utils/net";
import { useEffect, useState } from "react";
import { UseServiceCentersProps } from "./types";

export function useServiceCenters(props: UseServiceCentersProps) {
  const [internalLocations, setInternalLocations] =
    useState<InternalLocationModel[]>();

  const { capability } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!capability) return undefined;

    const params = new URLSearchParams();
    params.append("type", "service-center");
    params.append("capability", capability);

    const queryParams = params.toString();
    const url = `/internal-locations?${queryParams}`;
    return [url, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setInternalLocations([]);
      return;
    }

    const errorText = lodash(data).get("error_text");
    if (errorText) setInternalLocations([]);

    const interalLocations = data.data.map((item: Record<string, any>) =>
      InternalLocationModel.fromResponse(item)
    );

    setInternalLocations(interalLocations);
  }, [data]);

  return {
    serviceCenters: internalLocations,
    isLoading: isLoading,
    error: error,
  };
}
