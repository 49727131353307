import {
  Center,
  Button,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";

import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";

export function NotFoundPage() {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Center my={50}>
      <Container>
        <SimpleGrid
          breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
          className={classes.simpleGrid}
          cols={2}
          spacing={80}
        >
          <Image src="/assets/images/404.svg" withPlaceholder />
          <Stack>
            <Text
              style={{
                lineHeight: "48px",
                fontSize: "32px",
                fontWeight: "900",
              }}
            >
              Something is not right...
            </Text>
            <Text
              style={{
                lineHeight: "27px",
                fontSize: "18px",
                fontWeight: "400",
              }}
              color="dimmed"
            >
              Page you are trying to open does not exist. You may have mistyped
              the address, or the page has been moved to another URL. If you
              think this is an error contact support.
            </Text>
            <Button variant="outline" onClick={() => navigate(-1)}>
              Back to previous page
            </Button>
          </Stack>
        </SimpleGrid>
      </Container>
    </Center>
  );
}
