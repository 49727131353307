import useSWR from "swr";
import { useAuth } from "providers";
import { UseDeliveryFeeProps } from "./types";
import { fetcher } from "utils/net";
import { useEffect, useState } from "react";
import { DeliveryFeeModel } from "models";

export function useDeliveryFee(props: UseDeliveryFeeProps) {
  const [deliveryFee, setDeliveryFee] = useState<DeliveryFeeModel>();

  const { accessToken } = useAuth();
  const { originId, destinationId, weight, serviceId } = props;
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!originId) return undefined;
    if (!destinationId) return undefined;
    if (!serviceId) return undefined;
    if (!weight) return undefined;

    const query = new URLSearchParams();
    query.append("origin_id", originId);
    query.append("destination_id", destinationId);
    query.append("weight", `${weight}`);
    query.append("service_id", serviceId);

    const queryString = query.toString();
    const relUrl = `/delivery-fees?${queryString}`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setDeliveryFee(undefined);
      return;
    }

    const deliveryFee = DeliveryFeeModel.fromResponse(data);
    setDeliveryFee(deliveryFee);
  }, [data]);

  return {
    deliveryFee: deliveryFee,
    error: error,
    isLoading: isLoading,
  };
}
