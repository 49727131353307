import { Text, Space, Box, Stack, Title } from "@mantine/core";
import { WarehouseTransaction, WarrantyRegistration } from "./components";

export function AppSelectionPage() {
  return (
    <Box>
      <Title ta="center">Welcome</Title>
      <Text ta="center" c="dimmed">
        This is the application center. Please select an application to start.
      </Text>
      <Space h="lg" />
      <Space h="lg" />
      <Stack>
        <WarehouseTransaction />
        <WarrantyRegistration />
      </Stack>
    </Box>
  );
}
