import useSWR from "swr";
import { useAuth } from "providers";
import { useState, useEffect } from "react";

import { fetcher } from "utils/net";
import { CourierModel } from "models";
import { UseCouriersProps } from "./types";

export function useCouriers(props: UseCouriersProps) {
  const [couriers, setCouriers] = useState<CourierModel[]>([]);

  const { weight } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return;
    if (!weight) return;

    return [`/couriers?weight=${weight}`, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setCouriers([]);
      return;
    }

    const couriers = data.data.map((res: Record<string, any>) => {
      return CourierModel.fromResponse(res);
    });

    setCouriers(couriers);
  }, [data]);

  return {
    couriers: couriers,
    error: error,
    isLoading: isLoading,
  };
}
