import { createContext } from "react";
import { LoginContextProps, LoginProviderProps } from "./types";
import {
  funcNotImplemented,
  funcNotImplementedAsync,
} from "../../../../utils/misc";

import { useContext, useState } from "react";
import { useAuth } from "../../../../providers";

const LoginContext = createContext<LoginContextProps>({
  email: "",
  password: "",
  setEmail: funcNotImplemented,
  setPassword: funcNotImplemented,
  login: funcNotImplementedAsync,
});

export function LoginProvider(props: LoginProviderProps) {
  const { children } = props;
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login: funcLogin } = useAuth();

  async function login() {
    if (!email) return;
    if (!password) return;

    await funcLogin(email, password);
  }

  return (
    <LoginContext.Provider
      value={{
        email,
        password,
        setEmail,
        setPassword,
        login,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLoginProvider() {
  return useContext(LoginContext);
}
