import { TextInputComponent } from "components";
import { useFormProvider } from "../../../../providers";

export function PickupRequestComponent() {
  const { pickupRequest } = useFormProvider();

  return (
    <TextInputComponent
      label="ID Pengambilan"
      value={pickupRequest?.id}
      disabled
    />
  );
}
