import { createContext, useContext, useState } from "react";
import {
  DeliveryDestinationContextProps,
  DeliveryDestinationProviderProps,
} from "./types";
import { funcNotImplemented } from "utils/misc";
import { InternalLocationModel } from "models";

const DeliveryDestinationContext =
  createContext<DeliveryDestinationContextProps>({
    serviceCenter: undefined,
    setServiceCenter: funcNotImplemented,
  });

export function DeliveryDestinationProvider(
  props: DeliveryDestinationProviderProps
) {
  const { children } = props;
  const [serviceCenter, setServiceCenter] = useState<InternalLocationModel>();

  return (
    <DeliveryDestinationContext.Provider
      value={{
        serviceCenter,
        setServiceCenter,
      }}
    >
      {children}
    </DeliveryDestinationContext.Provider>
  );
}

export function useDeliveryDestinationProvider() {
  return useContext(DeliveryDestinationContext);
}
