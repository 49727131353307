import { FileInput } from "@mantine/core";
import { useFormProvider } from "../../../providers";

export function BarcodeInputComponent() {
  const { data, dispatch } = useFormProvider();

  function handleChange(value: File | null) {
    dispatch({ type: "SET_DATA", key: "barcode", value });
  }

  return (
    <FileInput
      label="Barcode Serial Number"
      accept="image/png,image/jpeg"
      value={data.barcode}
      onChange={handleChange}
      withAsterisk
    />
  );
}
