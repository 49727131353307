import validator from "validator";
import { useSerialNumber } from "hooks";
import { useCallback, useState } from "react";

import { Button } from "@mantine/core";
import {
  useDeviceInformationProvider,
  useFormProvider,
  useStepProvider,
} from "../../../../providers";

export function SubmitButtonComponent() {
  const { serialNumber, parts, complaint } = useDeviceInformationProvider();
  const { setSerialNumber, setParts, setComplaint } = useFormProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { nextStep } = useStepProvider();
  const [isLoading, setIsLoading] = useState(false);

  const ableToNextStep = useCallback(() => {
    if (!mSerialNumber) return false;
    if (!complaint) return false;
    if (validator.isEmpty(complaint)) return false;

    // We don't care about parts at the moment
    return true;
  }, [mSerialNumber, complaint]);

  async function handleClick() {
    try {
      setIsLoading(true);
      if (!ableToNextStep()) throw new Error(`Unable to go to next step`);

      // Save the data to formProvider
      setSerialNumber(serialNumber);
      setParts(parts);
      setComplaint(complaint);

      // Do a bit of a second delay
      const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));
      await delay();

      // Go to next step
      nextStep();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      onClick={handleClick}
      disabled={!ableToNextStep()}
      loading={isLoading}
    >
      Selanjutnya
    </Button>
  );
}
