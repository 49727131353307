import { createContext, useContext, useState } from "react";
import { funcNotImplemented } from "utils/misc";
import { CourierModel, CourierServiceModel } from "models";
import {
  CourierInformationContextProps,
  CourierInformationProviderProps,
} from "./types";

const CourierInformationContext = createContext<CourierInformationContextProps>(
  {
    courier: undefined,
    courierService: undefined,
    setCourier: funcNotImplemented,
    setCourierService: funcNotImplemented,
  }
);

export function CourierInformationProvider(
  props: CourierInformationProviderProps
) {
  const { children } = props;

  const [courier, setCourier] = useState<CourierModel>();
  const [courierService, setCourierService] = useState<CourierServiceModel>();

  return (
    <CourierInformationContext.Provider
      value={{
        courier,
        courierService,
        setCourier,
        setCourierService,
      }}
    >
      {children}
    </CourierInformationContext.Provider>
  );
}

export function useCourierInformationProvider() {
  return useContext(CourierInformationContext);
}
