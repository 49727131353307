import { Button } from "@mantine/core";
import { useStepProvider } from "../../providers";

export function BackButtonComponent() {
  const { prevStep } = useStepProvider();

  return (
    <Button variant="outline" onClick={prevStep}>
      Kembali
    </Button>
  );
}
