import {
  BrandComponent,
  DeviceComponent,
  PartsComponent,
  SerialNumberComponent,
  SubmitButtonComponent,
  ComplaintComponent,
} from "./components";

export function DeviceInformationForm() {
  return <></>;
}

DeviceInformationForm.Brand = BrandComponent;
DeviceInformationForm.Device = DeviceComponent;
DeviceInformationForm.Parts = PartsComponent;
DeviceInformationForm.SerialNumber = SerialNumberComponent;
DeviceInformationForm.SubmitButton = SubmitButtonComponent;
DeviceInformationForm.Complaint = ComplaintComponent;
