import {
  PickupRequestComponent,
  PaymentLinkComponent,
  PayButtonComponent,
} from "./components";

export function PaymentInformationForm() {
  return <></>;
}

PaymentInformationForm.PickupRequest = PickupRequestComponent;
PaymentInformationForm.PaymentLink = PaymentLinkComponent;
PaymentInformationForm.PayButton = PayButtonComponent;
