import { BarcodeInputComponent } from "./BarcodeInput";
import { DeviceInputComponent } from "./DeviceInput";
import { InvoiceInputComponent } from "./InvoiceInput";
import { SerialNumberInputComponent } from "./SerialNumberInput";

export function FormInputComponent() {
  return <></>;
}

FormInputComponent.Invoice = InvoiceInputComponent;
FormInputComponent.Barcode = BarcodeInputComponent;
FormInputComponent.SerialNumber = SerialNumberInputComponent;
FormInputComponent.Device = DeviceInputComponent;
