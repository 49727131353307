import { DispatchAction, FormData } from "./types";

export const initialData = {
  invoice: null,
  barcode: null,
  device: null,
  serialNumber: "",
};

export const reducer = (state: FormData, action: DispatchAction) => {
  switch (action.type) {
    case "SET_DATA":
      return Object.assign({}, state, { [action.key]: action.value });
    case "CLEAN":
      return initialData;
    default:
      return state;
  }
};
