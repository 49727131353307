import lodash from "lodash";
import { Button } from "@mantine/core";
import { useFormProvider, useStepProvider } from "../../../../providers";
import { usePaymentLink, usePickupRequestInvoices } from "hooks";
import { useEffect } from "react";

export function PayButtonComponent() {
  const { pickupRequest } = useFormProvider();
  const { nextStep } = useStepProvider();
  const { invoices } = usePickupRequestInvoices({
    pickupRequestId: pickupRequest?.id,
  });

  const { paymentLink } = usePaymentLink({
    paymentLinkId: invoices?.[0]?.paymentLinkId,
  });

  useEffect(() => {
    if (!paymentLink) return;
    if (paymentLink.status === "paid") nextStep();
  }, [paymentLink, nextStep]);

  function handleClick() {
    if (!invoices) return;
    if (invoices.length === 0) return;

    const invoice = lodash(invoices).first();
    if (!invoice) return;

    const paymentLink = invoice.paymentLink.url;
    window.open(paymentLink, "_blank");
  }

  return <Button onClick={handleClick}>Bayar Sekarang</Button>;
}
