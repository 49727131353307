import { useDeliveryFee } from "hooks";

import { CourierDeliveryFeeComponentProps } from "./types";
import { TextInputComponent } from "components";
import { Loader } from "@mantine/core";

export function CourierDeliveryFeeComponent(
  props: CourierDeliveryFeeComponentProps
) {
  const { originId, destinationId, weight, serviceId, disabled, isLoading } =
    props;

  const { deliveryFee } = useDeliveryFee({
    originId,
    destinationId,
    weight,
    serviceId,
  });

  function formatPrice(price: number) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(price);
  }

  function generateLoader() {
    if (isLoading) return <Loader size="xs" />;
    else return <></>;
  }

  return (
    <TextInputComponent
      label="Ongkos Kirim"
      value={formatPrice(deliveryFee?.price ?? 0)}
      disabled={disabled === undefined ? true : disabled}
      rightSection={generateLoader()}
    />
  );
}
