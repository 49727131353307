import { Button } from "@mantine/core";

import { useCallback, useState } from "react";
import {
  useCourierInformationProvider,
  useFormProvider,
  useStepProvider,
} from "../../../../providers";

export function SubmitButtonComponent() {
  const { nextStep } = useStepProvider();
  const { setCourier, setCourierService } = useFormProvider();
  const { courier, courierService } = useCourierInformationProvider();
  const [isLoading, setIsLoading] = useState(false);

  const ableToNextStep = useCallback(() => {
    if (!courier) return false;
    if (!courierService) return false;
    return true;
  }, [courier, courierService]);

  async function handleClick() {
    try {
      setIsLoading(true);
      if (!ableToNextStep()) throw new Error(`Unable to go to next step`);

      // Save the data to formProvider
      setCourier(courier);
      setCourierService(courierService);

      // Do a bit of a second delay
      const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));
      await delay();

      // Go to next step
      nextStep();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      onClick={handleClick}
      disabled={!ableToNextStep()}
      loading={isLoading}
    >
      Selanjutnya
    </Button>
  );
}
