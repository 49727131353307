import lodash from "lodash";
import { UseDevicePartsProps } from "./types";

import useSWR from "swr";
import { useAuth } from "providers";
import { useEffect, useState } from "react";
import { SparePartModel } from "models";
import { fetcher } from "utils/net";

export function useDeviceParts(props: UseDevicePartsProps) {
  const [parts, setParts] = useState<SparePartModel[]>();

  const { deviceId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!deviceId) return;
    if (!accessToken) return;

    const relUrl = `/devices/${deviceId}/parts`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setParts([]);
      return;
    }

    const errorText = lodash(data).get("error_text");
    if (errorText) setParts([]);

    const parts = data.data.map((item: Record<string, any>) =>
      SparePartModel.fromResponse(item)
    );

    setParts(parts);
  }, [data]);

  return { parts: parts, isLoading: isLoading, error: error };
}
