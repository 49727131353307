import {
  SelectCourierComponent,
  SelectCourierServiceComponent,
  DeliveryFeeComponent,
  SubmitButtonComponent,
  InsuranceFeeComponent,
} from "./components";

export function CourierInformationForm() {
  return <></>;
}

CourierInformationForm.SelectCourier = SelectCourierComponent;
CourierInformationForm.SelectCourierService = SelectCourierServiceComponent;
CourierInformationForm.DeliveryFee = DeliveryFeeComponent;
CourierInformationForm.SubmitButton = SubmitButtonComponent;
CourierInformationForm.InsuranceFee = InsuranceFeeComponent;
