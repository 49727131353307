import useSWR from "swr";
import { useAuth } from "providers";
import { useState, useEffect } from "react";

import { fetcher } from "utils/net";
import { DeviceDeliveryInformationModel } from "models";
import { UseDeviceDeliveryInformationsProps } from "./types";

export function useDeviceDeliveryInformations(
  props: UseDeviceDeliveryInformationsProps
) {
  const [ddis, setDdis] = useState<DeviceDeliveryInformationModel[]>([]);

  const { deviceId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!deviceId) return undefined;

    return [`/device-delivery-informations?device_id=${deviceId}`, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setDdis([]);
      return;
    }

    const ddis = data.data.map((res: Record<string, any>) => {
      return DeviceDeliveryInformationModel.fromResponse(res);
    });

    setDdis(ddis);
  }, [data]);

  return {
    deviceDeliveryInformations: ddis,
    error: error,
    isLoading: isLoading,
  };
}
