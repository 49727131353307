import { Select } from "@mantine/core";
import { useServiceCenters } from "hooks";
import { ServiceCenterComponentProps } from "./types";
import { InternalLocationModel } from "models";

export function ServiceCenterSelectComponent(
  props: ServiceCenterComponentProps
) {
  const { label, placeholder, value, disabled, onChange } = props;
  const { serviceCenters } = useServiceCenters({ capability: "mazuta:pickup" });

  function generateLabel() {
    if (!label) return "Service Center";
    else return label;
  }

  function generatePlaceholder() {
    if (!placeholder) return "Pilih Service Center";
    else return placeholder;
  }

  function generateData(serviceCenters?: InternalLocationModel[]) {
    if (!serviceCenters) return [];
    return serviceCenters.map((serviceCenter) => {
      return {
        value: serviceCenter.id,
        label: serviceCenter.name,
      };
    });
  }

  function handleChange(value: string) {
    if (!serviceCenters) return;
    if (!onChange) return;

    const findFunction = (serviceCenter: InternalLocationModel) =>
      serviceCenter.id === value;

    const serviceCenter = serviceCenters.find(findFunction);
    if (onChange) onChange(serviceCenter);
  }

  return (
    <Select
      label={generateLabel()}
      placeholder={generatePlaceholder()}
      value={value?.id}
      onChange={handleChange}
      data={generateData(serviceCenters)}
      disabled={disabled}
    />
  );
}
