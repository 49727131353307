import { FormInput } from "components";
import { useSenderInformationProvider } from "../../../../providers";

export function PhoneNumberComponent() {
  const { phoneNumber, setPhoneNumber } = useSenderInformationProvider();

  return (
    <FormInput.PhoneNumber value={phoneNumber} onChange={setPhoneNumber} />
  );
}
