import { useDeviceParts, useSerialNumber } from "hooks";
import { MultiSelect } from "@mantine/core";
import { useDeviceInformationProvider } from "../../../../providers";

export function PartsComponent() {
  const { serialNumber, parts, setParts } = useDeviceInformationProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { parts: mParts } = useDeviceParts({
    deviceId: mSerialNumber?.device.id,
  });

  function handleChange(values: string[]) {
    if (!mParts || mParts.length === 0) return;
    const selectedParts = mParts.filter((part) => values.includes(part.id));
    setParts(selectedParts);
  }

  function generateData() {
    if (!mParts || mParts.length === 0) return [];
    return mParts.map((part) => ({
      value: part.id,
      label: part.name,
    }));
  }

  function generateValue() {
    if (!parts || parts.length === 0) return [];
    return parts.map((part) => part.id);
  }

  return (
    <MultiSelect
      label="Kelengkapan"
      data={generateData()}
      onChange={handleChange}
      value={generateValue()}
    />
  );
}
