import { createContext } from "react";
import { useContext, useState } from "react";
import { funcNotImplemented } from "utils/misc";
import { StepContextProps, StepProviderProps } from "./types";

const StepContext = createContext<StepContextProps>({
  activeStep: 0,
  nextStep: funcNotImplemented,
  prevStep: funcNotImplemented,
  onStepClick: funcNotImplemented,
  gotoPaymentStep: funcNotImplemented,
  gotoCompletedStep: funcNotImplemented,
});

export function StepProvider(props: StepProviderProps) {
  const { children, maxSteps } = props;
  const [activeStep, setActiveStep] = useState<number>(0);

  function onStepClick(step: number) {
    setActiveStep(step);
  }

  function nextStep() {
    setActiveStep((prev) => {
      if (prev === maxSteps) return prev;
      return prev + 1;
    });
  }

  function prevStep() {
    setActiveStep((prev) => {
      if (prev === 0) return prev;
      return prev - 1;
    });
  }

  function gotoPaymentStep() {
    setActiveStep(maxSteps - 1);
  }

  function gotoCompletedStep() {
    setActiveStep(maxSteps);
  }

  return (
    <StepContext.Provider
      value={{
        activeStep,
        nextStep,
        prevStep,
        onStepClick,
        gotoPaymentStep,
        gotoCompletedStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
}

export function useStepProvider() {
  return useContext(StepContext);
}
