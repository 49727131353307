import { useStepProvider } from "../../providers";
import { Stepper } from "@mantine/core";
import {
  CompletedStep,
  CourierInformationStep,
  DeliveryDestinationStep,
  DeviceInformationStep,
  PaymentInformationStep,
  ReviewStep,
  SenderInformationStep,
} from "..";

export function StepsComponent() {
  const { activeStep, onStepClick } = useStepProvider();

  return (
    <Stepper
      active={activeStep}
      onStepClick={onStepClick}
      breakpoint="sm"
      orientation="vertical"
    >
      <Stepper.Step label="Data Perangkat" allowStepClick>
        <DeviceInformationStep />
      </Stepper.Step>
      <Stepper.Step
        label="Service Center Tujuan"
        allowStepClick={false}
        allowStepSelect={false}
      >
        <DeliveryDestinationStep />
      </Stepper.Step>
      <Stepper.Step
        label="Data Pengirim"
        allowStepClick={false}
        allowStepSelect={false}
      >
        <SenderInformationStep />
      </Stepper.Step>
      <Stepper.Step
        label="Data Kurir"
        allowStepClick={false}
        allowStepSelect={false}
      >
        <CourierInformationStep />
      </Stepper.Step>
      <Stepper.Step
        label="Review"
        allowStepClick={false}
        allowStepSelect={false}
      >
        <ReviewStep />
      </Stepper.Step>
      <Stepper.Step
        label="Pembayaran"
        allowStepClick={false}
        allowStepSelect={false}
      >
        <PaymentInformationStep />
      </Stepper.Step>
      <Stepper.Completed>
        <CompletedStep />
      </Stepper.Completed>
    </Stepper>
  );
}
