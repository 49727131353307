import { ApplicationWrapperProps } from "./types";
import { Link } from "react-router-dom";
import { useState } from "react";

import {
  Text,
  Box,
  AppShell,
  Header,
  useMantineTheme,
  Navbar,
  MediaQuery,
  Burger,
} from "@mantine/core";

export function ApplicationWrapper(props: ApplicationWrapperProps) {
  const { children } = props;
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();

  return (
    <AppShell
      navbarOffsetBreakpoint="xl"
      navbar={
        <Navbar
          hiddenBreakpoint="xl"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Navbar.Section m="md">
            <Text component={Link} to="/logout">
              Logout
            </Text>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header
          height={{ base: 50, md: 70 }}
          p="md"
          style={{ backgroundColor: theme.colors[theme.primaryColor][6] }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              color: "white",
            }}
          >
            <MediaQuery largerThan="xl" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color="white"
                mr="xl"
              />
            </MediaQuery>
            <Text fw={700}>Mazuta Application Center</Text>
          </Box>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}
