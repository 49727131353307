import { TextInputComponent } from "components/TextInput";
import { Text } from "@mantine/core";
import { PhoneNumberComponentProps } from "./types";

export function PhoneNumberComponent(props: PhoneNumberComponentProps) {
  const { value, disabled, onChange } = props;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) onChange(e.target.value);
  }

  return (
    <TextInputComponent
      label="Nomor Telpon"
      placeholder="8123456789"
      icon={<Text fz="xs">+62</Text>}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}
