import { Textarea } from "@mantine/core";
import { useDeviceInformationProvider } from "../../../../providers";

export function ComplaintComponent() {
  const { complaint, setComplaint } = useDeviceInformationProvider();

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setComplaint(e.target.value);
  }

  return (
    <Textarea
      label="Keluhan"
      minRows={5}
      value={complaint}
      onChange={handleChange}
    />
  );
}
