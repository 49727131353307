import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "providers/auth/hook";
import { useEffect, useState } from "react";

export function PrivateRouteComponent() {
  const { accessToken, logout } = useAuth();
  const [isValidToken, setIsValidToken] = useState(false);
  const [isChecking, setIsChecking] = useState<boolean>(true);

  useEffect(() => {
    setIsChecking(true);
    setIsValidToken(false);

    const isJSON = (str: string) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };

    if (!accessToken) {
      setIsChecking(false);
      setIsValidToken(false);
      logout();
      return;
    }

    if (isJSON(accessToken)) {
      setIsChecking(false);
      setIsValidToken(false);
      logout();
    } else {
      if (accessToken) {
        setIsChecking(false);
        setIsValidToken(true);
      } else {
        setIsChecking(false);
        setIsValidToken(false);
        logout();
      }
    }
  }, [accessToken, logout]);

  if (isChecking) return <>Checking Token...</>;
  return isValidToken ? <Outlet /> : <Navigate to="/login" replace={true} />;
}
