import Config from "configs/api";
import axios from "axios";
import { DateTime } from "luxon";
import { AuthContext } from "./context";
import { ProviderProps } from "./types";

import { useState } from "react";
import { useDefaultSystem } from "../../hooks";

export function AuthProvider(props: ProviderProps) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(
    window.localStorage.getItem("access_token") ?? undefined
  );

  const { notificationSystem } = useDefaultSystem();

  function apiKeyLogin() {
    const strApiKey = JSON.stringify({ apiKey: Config.apiKey });
    window.localStorage.setItem("access_token", strApiKey);
    setAccessToken(strApiKey);
  }

  async function login(email: string, password: string) {
    setIsFetching(true);
    try {
      const url = "https://mazuta.jp.auth0.com/oauth/token";
      const body = {
        grant_type: "password",
        client_id: Config.auth.clientId,
        username: email,
        password: password,
        audience: Config.auth.audience,
        scope: "offline_access",
      };

      const response = await axios.post(url, body);
      window.localStorage.setItem("access_token", response.data.access_token);
      window.localStorage.setItem("refresh_token", response.data.refresh_token);

      const expireInSeconds = response.data.expires_in;
      const expiredAt = DateTime.utc().plus({ seconds: expireInSeconds });
      window.localStorage.setItem("expired_at", expiredAt.toISO());

      setAccessToken(response.data.access_token);
    } catch (err: any) {
      notificationSystem({
        isSuccess: false,
        message: err.response.data.error_description,
      });
    }
    setIsFetching(false);
  }

  function logout() {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("expired_at");
    setAccessToken(undefined);
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken: accessToken,
        isFetching: isFetching,
        apiKeyLogin: apiKeyLogin,
        login: login,
        logout: logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
