import { Properties, Constructor } from "./types";
import { DateTime } from "luxon";

export interface PickupRequestModel extends Properties {}
export class PickupRequestModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(response: Record<string, any>) {
    return new PickupRequestModel({
      id: response.id,
      createdAt: DateTime.fromISO(response.created_at),
      updatedAt: DateTime.fromISO(response.updated_at),
      deletedAt: response.deletedAt && DateTime.fromISO(response.deleted_at),
    });
  }
}
