import { FileInput } from "@mantine/core";
import { useFormProvider } from "../../../providers";

export function InvoiceInputComponent() {
  const { data, dispatch } = useFormProvider();

  function handleChange(value: File | null) {
    dispatch({ type: "SET_DATA", key: "invoice", value });
  }

  return (
    <FileInput
      label="Nota Pembelian"
      accept="image/png,image/jpeg"
      value={data.invoice}
      onChange={handleChange}
      withAsterisk
    />
  );
}
