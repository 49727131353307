import { useStyles } from "./styles";

import { Center, Paper, Title, Text, Space, Stack, Box } from "@mantine/core";
import { FormInput, LoginButtonComponent, LogoComponent } from "./components";
import { LoginProvider } from "./providers";

export function LoginPage() {
  const { classes } = useStyles();

  return (
    <LoginProvider>
      <Box className={classes.wrapper}>
        <Box className={classes.bg} />
        <Box className={classes.logoWrapper}>
          <LogoComponent />
        </Box>
        <Box className={classes.main}>
          <Paper w="95%" mt="lg" p="md">
            <Center>
              <Title order={1} style={{ fontFamily: "Playfair Display" }}>
                Welcome Back!
              </Title>
            </Center>
            <Center>
              <Text color="dimmed">Please enter your details to login</Text>
            </Center>
            <Space h="lg" />
            <Stack>
              <FormInput.Email />
              <FormInput.Password />
              <LoginButtonComponent />
            </Stack>
          </Paper>
        </Box>
      </Box>
    </LoginProvider>
  );
}
