import lodash from "lodash";
import { UseCourierInsuranceFeeProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers";
import { useEffect, useState } from "react";

export function useCourierInsuranceFee(props: UseCourierInsuranceFeeProps) {
  const [insuranceFee, setInsuranceFee] = useState<number>();

  const { courierId, insuredValue } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!courierId) return undefined;
    if (!insuredValue) return undefined;
    if (!accessToken) return undefined;

    const relUrl = `/couriers/${courierId}/insurance-fee?insured_value=${insuredValue}`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setInsuranceFee(0);
      return;
    }

    const insuranceFee = lodash(data).get("insurance_fee");
    setInsuranceFee(insuranceFee);
  }, [data]);

  return {
    insuranceFee: insuranceFee,
    error: error,
    isLoading: isLoading,
  };
}
