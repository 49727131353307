import lodash from "lodash";
import { Box, Stack, Text } from "@mantine/core";
import { WarehouseTransactionConfirmationModalProps } from "./types";
import { ModalComponent } from "components";
import { useState } from "react";
import { useAuth } from "providers";
import { loadingComplete, showLoading } from "utils/loader";
import { net, responseChecker } from "utils/net";
import { SerialNumberModel } from "models";
import { AxiosError } from "axios";

export function WarehouseTransactionConfirmationModal(
  props: WarehouseTransactionConfirmationModalProps
) {
  const { context, id, innerProps } = props;
  const { serialNumber, onSubmit } = innerProps;
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function findSerialNumber(sn: string, accessToken: string) {
    try {
      const qs = new URLSearchParams();
      qs.append("serial_number", sn);

      const relUrl = `/serial-numbers/find?${qs.toString()}`;
      const netInstance = await net(accessToken);
      const res = await netInstance.get(relUrl);

      if (res.data.error_text) return undefined;
      else return SerialNumberModel.fromResponse(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 404) return undefined;
        else throw err;
      } else throw err;
    }
  }

  async function createTransaction(
    sn: SerialNumberModel,
    direction: string,
    accessToken: string
  ) {
    try {
      const acceptedDirections = ["in", "out"];
      if (!acceptedDirections.includes(direction)) return;

      const relUrl = `/warehouse-transactions`;
      const body = { serial_number_id: sn.id, direction };
      const netInstance = await net(accessToken);
      const res = await netInstance.post(relUrl, body);

      const errorText = lodash(res).get("data.error_text", undefined);
      if (errorText) throw new Error(errorText);
    } catch (err: any) {
      const errText = err.response.data.error_text;
      throw new Error(errText);
    }
  }

  async function createTransactionWithNewSerialNumber(
    sn: string,
    accessToken: string
  ) {
    try {
      const relUrl = `/warehouse-transactions/new-serial-number`;
      const body = { serial_number: sn };
      const netInstance = await net(accessToken);
      const res = await netInstance.post(relUrl, body);
      responseChecker(res);
    } catch (err: any) {
      const errText = err.response.data.error_text;
      throw new Error(errText);
    }
  }

  // This will submit the data after confirmation
  async function realSubmit() {
    if (!accessToken) return;
    try {
      setIsLoading(true);
      showLoading("warehouse-transactions.out");
      // Find the serial number from database
      const foundSn = await findSerialNumber(serialNumber, accessToken);
      if (!foundSn) {
        // SN is not found here, so we need to create a new SN in the database
        // We might need to flag it or not.
        await createTransactionWithNewSerialNumber(serialNumber, accessToken);
      } else {
        // Now we will insert the transaction to database
        await createTransaction(foundSn, "out", accessToken);
      }
      // await createTransaction(foundSn, "out", accessToken);
      loadingComplete("warehouse-transactions.out", false);
    } catch (err: any) {
      loadingComplete("warehouse-transactions.out", true, err.message);
    } finally {
      setIsLoading(false);
      context.closeModal(id);
      if (onSubmit) onSubmit();
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await realSubmit();
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <Text>Serial Number: {serialNumber}</Text>
        <ModalComponent.ActionButtons
          context={context}
          id={id}
          cancel={{ label: "Salah" }}
          submit={{ label: "Benar", disabled: isLoading }}
        />
      </Stack>
    </Box>
  );
}
