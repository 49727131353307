import { DateTime } from "luxon";
import { Properties, Constructor } from "./types";

export interface PaymentLinkModel extends Properties {}
export class PaymentLinkModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(response: Record<string, any>) {
    return new PaymentLinkModel({
      id: response.id,
      url: response.url,
      status: response.status,
      createdAt: DateTime.fromISO(response.created_at),
      updatedAt: DateTime.fromISO(response.updated_at),
      deletedAt: response.deleted_at && DateTime.fromISO(response.deleted_at),
    });
  }
}
