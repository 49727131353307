import { TextInputComponent } from "components";
import { useDeviceInformationProvider } from "../../../../providers";
import { useSerialNumber } from "hooks";

export function BrandComponent() {
  const { serialNumber } = useDeviceInformationProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });

  return (
    <TextInputComponent
      label="Merk"
      placeholder="Merk"
      value={(mSerialNumber && mSerialNumber.device.brand.name) ?? ""}
      disabled
    />
  );
}
