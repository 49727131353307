import { Select } from "@mantine/core";
import { useFormProvider } from "../../../../providers";
import { OriginLocationModel } from "models";

export function PickupLocationComponent() {
  const { originLocation } = useFormProvider();

  function generateValue(loc?: OriginLocationModel) {
    if (!loc) return "";
    return loc.id;
  }

  function generateData(loc?: OriginLocationModel) {
    if (!loc) return [];
    return [
      {
        label: loc.name,
        value: loc.id,
      },
    ];
  }

  return (
    <Select
      label="Kota Pengambilan"
      value={generateValue(originLocation)}
      data={generateData(originLocation)}
      disabled
    />
  );
}
