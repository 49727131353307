import { TextInputComponent } from "components";
import { useSenderInformationProvider } from "../../../../providers";

export function NameComponent() {
  const { name, setName } = useSenderInformationProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  return (
    <TextInputComponent
      label="Nama"
      placeholder="Nama"
      value={name}
      onChange={handleChange}
    />
  );
}
