import useSWR from "swr";
import { useAuth } from "providers";
import { useState, useEffect } from "react";

import { fetcher } from "utils/net";
import { CourierServiceModel } from "models";
import { UseCourierServicesProps } from "./types";

export function useCourierServices(props: UseCourierServicesProps) {
  const [services, setServices] = useState<CourierServiceModel[]>([]);

  const { courierId, weight } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;
    if (!courierId) return undefined;
    if (!weight) return undefined;

    const relUrl = `/couriers/${courierId}/services?weight=${weight}`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setServices([]);
      return;
    }

    const services = data.data.map((s: Record<string, any>) => {
      return CourierServiceModel.fromResponse(s);
    });

    setServices(services);
  }, [data]);

  return {
    courierServices: services,
    error: error,
    isLoading: isLoading,
  };
}
