import { PaymentLinkModel } from "models";
import { UsePaymentLinkProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useEffect, useState } from "react";
import { useAuth } from "providers";

export function usePaymentLink(props: UsePaymentLinkProps) {
  const [paymentLink, setPaymentLink] = useState<PaymentLinkModel>();

  const { paymentLinkId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(
    () => {
      if (!accessToken) return undefined;
      if (!paymentLinkId) return undefined;

      const url = `/payment-links/${paymentLinkId}`;
      return [url, accessToken];
    },
    fetcher,
    { refreshInterval: 1000 }
  );

  useEffect(() => {
    if (!data) {
      setPaymentLink(undefined);
      return;
    }

    const paymentLink = PaymentLinkModel.fromResponse(data);
    setPaymentLink(paymentLink);
  }, [data]);

  return {
    paymentLink: paymentLink,
    error: error,
    isLoading: isLoading,
  };
}
