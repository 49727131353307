import validator from "validator";
import { FormContextProps, FormProviderProps } from "./types";
import { createContext } from "react";
import { initialData, reducer } from "./reducer";
import { funcNotImplemented } from "utils/misc";
import { useContext, useReducer, useState, useEffect } from "react";

const FormContext = createContext<FormContextProps>({
  data: initialData,
  isClean: false,
  dispatch: funcNotImplemented,
});

export function FormProvider(props: FormProviderProps) {
  const { children } = props;
  const [data, dispatch] = useReducer(reducer, initialData);
  const [isClean, setIsClean] = useState<boolean>(false);

  useEffect(() => {
    setIsClean(
      data.invoice !== undefined &&
        data.barcode !== undefined &&
        data.device !== undefined &&
        data.serialNumber !== undefined &&
        data.invoice !== null &&
        data.barcode !== null &&
        data.device !== null &&
        !validator.isEmpty(data.serialNumber)
    );

    console.log(data);
  }, [data]);

  return (
    <FormContext.Provider value={{ data, isClean, dispatch }}>
      {children}
    </FormContext.Provider>
  );
}

export function useFormProvider() {
  return useContext(FormContext);
}
