import lodash from "lodash";
import { Box, Text } from "@mantine/core";
import { useFormProvider } from "../../../../providers";
import { useCallback } from "react";
import { usePickupRequestInvoices } from "hooks";

export function PaymentLinkComponent() {
  const { pickupRequest } = useFormProvider();
  const { invoices } = usePickupRequestInvoices({
    pickupRequestId: pickupRequest?.id,
  });

  const retrieveLink = useCallback(() => {
    if (!invoices) return;
    if (invoices.length === 0) return;

    const invoice = lodash(invoices).first();
    if (!invoice) return;

    const paymentLink = invoice.paymentLink.url;
    return paymentLink;
  }, [invoices]);

  return (
    <Box style={{ overflowWrap: "anywhere" }}>
      <Text component="a" variant="link" href={retrieveLink()}>
        {retrieveLink()}
      </Text>
    </Box>
  );
}
