import { ApplicationWrapper } from "components";
import { Outlet } from "react-router-dom";

export function AppsPage() {
  return (
    <ApplicationWrapper>
      <Outlet />
    </ApplicationWrapper>
  );
}
