import lodash from "lodash";
import { SerialNumberModel } from "models";
import { UseSerialNumberProps } from "./types";
import { fetcher } from "utils/net";

import useSWR from "swr";
import { useAuth } from "providers";
import { useEffect, useState } from "react";

export function useSerialNumber(props: UseSerialNumberProps) {
  const { serialNumber, serialNumberId } = props;
  const [mSerialNumber, setSerialNumber] = useState<SerialNumberModel>();

  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;

    // We need to define if we are using serial number or serial number id
    // because both has different endpoint
    const generateUrl = (serialNumber?: string, serialNumberId?: string) => {
      if (serialNumber) {
        return `/serial-numbers/find?serial_number=${serialNumber}`;
      } else if (serialNumberId) {
        return `/serial-numbers/${serialNumberId}`;
      } else throw new Error("Serial number or serial number id is required");
    };

    const relativeUrl = generateUrl(serialNumber, serialNumberId);
    return [relativeUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setSerialNumber(undefined);
      return;
    }

    const errorText = lodash(data).get("error_text");
    if (errorText) setSerialNumber(undefined);

    const serialNumber = SerialNumberModel.fromResponse(data);
    setSerialNumber(serialNumber);
  }, [data]);

  return {
    serialNumber: mSerialNumber,
    isLoading: isLoading,
    error: error,
  };
}
