import { Select } from "@mantine/core";
import { useFormProvider } from "../../../../providers";
import { CourierModel } from "models";

export function CourierComponent() {
  const { courier } = useFormProvider();

  function genearateValue(courier?: CourierModel) {
    if (!courier) return "";
    return courier.id;
  }

  function generateData(courier?: CourierModel) {
    if (!courier) return [];
    return [
      {
        label: courier.name,
        value: courier.id,
      },
    ];
  }

  return (
    <Select
      label="Kurir"
      value={genearateValue(courier)}
      data={generateData(courier)}
      disabled
    />
  );
}
