import { FormContextProps, FormProviderProps } from "./types";
import { useContext, useState } from "react";
import { createContext } from "react";
import { funcNotImplemented } from "utils/misc";
import {
  CourierModel,
  CourierServiceModel,
  InternalLocationModel,
  OriginLocationModel,
  PickupRequestModel,
  SparePartModel,
} from "models";

const FormContext = createContext<FormContextProps>({
  serialNumber: "",
  parts: [] as SparePartModel[],
  complaint: "",

  serviceCenter: undefined,

  name: "",
  phoneNumber: "",
  address: "",
  originLocation: undefined,

  courier: undefined,
  courierService: undefined,

  pickupRequest: undefined,

  setSerialNumber: funcNotImplemented,
  setParts: funcNotImplemented,
  setComplaint: funcNotImplemented,

  setServiceCenter: funcNotImplemented,

  setName: funcNotImplemented,
  setPhoneNumber: funcNotImplemented,
  setAddress: funcNotImplemented,
  setOriginLocation: funcNotImplemented,

  setCourier: funcNotImplemented,
  setCourierService: funcNotImplemented,

  setPickupRequest: funcNotImplemented,
});

export function FormProvider(props: FormProviderProps) {
  const [serialNumber, setSerialNumber] = useState<string>(""); // E01H15520D09HMPE0232
  const [parts, setParts] = useState<SparePartModel[]>([]);
  const [complaint, setComplaint] = useState<string>("");

  const [serviceCenter, setServiceCenter] = useState<InternalLocationModel>();

  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [originLocation, setOriginLocation] = useState<OriginLocationModel>();

  const [courier, setCourier] = useState<CourierModel>();
  const [courierService, setCourierService] = useState<CourierServiceModel>();

  const [pickupRequest, setPickupRequest] = useState<PickupRequestModel>();

  return (
    <FormContext.Provider
      value={{
        serialNumber,
        parts,
        complaint,

        serviceCenter,

        name,
        phoneNumber,
        address,
        originLocation,

        courier,
        courierService,

        pickupRequest,

        setSerialNumber,
        setParts,
        setComplaint,

        setServiceCenter,

        setName,
        setPhoneNumber,
        setAddress,
        setOriginLocation,

        setCourier,
        setCourierService,

        setPickupRequest,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
}

export function useFormProvider() {
  return useContext(FormContext);
}
