import {
  ServiceCenterSelectComponent,
  PhoneNumberComponent,
  CourierDeliveryFeeComponent,
  CourierInsuranceFeeComponent,
} from "./components";

export function FormInput() {
  return <></>;
}

FormInput.ServiceCenterSelect = ServiceCenterSelectComponent;
FormInput.PhoneNumber = PhoneNumberComponent;
FormInput.CourierDeliveryFee = CourierDeliveryFeeComponent;
FormInput.CourierInsuranceFee = CourierInsuranceFeeComponent;
