import { FormInput } from "components";
import { useDeliveryDestinationProvider } from "../../../../providers";

export function SelectServiceCenterComponent() {
  const { serviceCenter, setServiceCenter } = useDeliveryDestinationProvider();

  return (
    <FormInput.ServiceCenterSelect
      value={serviceCenter}
      onChange={setServiceCenter}
    />
  );
}
