import { TextInputComponent } from "components";
import { useDeviceInformationProvider } from "../../../../providers";
import { useSerialNumber } from "hooks";

export function DeviceComponent() {
  const { serialNumber } = useDeviceInformationProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });

  return (
    <TextInputComponent
      label="Jenis Perangkat"
      placeholder="Jenis Perangkat"
      value={(mSerialNumber && mSerialNumber.device.name) ?? ""}
      disabled
    />
  );
}
