import { useDeviceInformationProvider } from "../../../../providers";
import { useSerialNumber } from "hooks";

import { TextInputComponent } from "components";

export function SerialNumberComponent() {
  const { serialNumber, setSerialNumber } = useDeviceInformationProvider();
  const { error } = useSerialNumber({ serialNumber });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSerialNumber(event.target.value);
  }

  return (
    <TextInputComponent
      label="Serial Number"
      placeholder="Serial Number"
      value={serialNumber}
      onChange={handleChange}
      error={error}
    />
  );
}
