import { Textarea } from "@mantine/core";
import { useSenderInformationProvider } from "../../../../providers";

export function AddressComponent() {
  const { address, setAddress } = useSenderInformationProvider();

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setAddress(e.target.value);
  }

  return (
    <Textarea
      label="Alamat"
      placeholder="Alamat"
      value={address}
      onChange={handleChange}
    />
  );
}
