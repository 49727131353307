import { TextInputComponent } from "components";
import { useFormProvider } from "../../../../providers";

export function SerialNumberComponent() {
  const { serialNumber } = useFormProvider();

  return (
    <TextInputComponent label="Serial Number" value={serialNumber} disabled />
  );
}
