import { TextInputComponent } from "components";
import { CourierInsuranceFeeComponentProps } from "./types";
import { useCourierInsuranceFee } from "hooks";
import { Loader } from "@mantine/core";

export function CourierInsuranceFeeComponent(
  props: CourierInsuranceFeeComponentProps
) {
  const { courier, insuredValue, isLoading } = props;
  const { insuranceFee } = useCourierInsuranceFee({
    courierId: courier?.id,
    insuredValue: insuredValue,
  });

  function formatPrice(price: number) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(price);
  }

  function generateLoader() {
    if (isLoading) return <Loader size="xs" />;
    else return <></>;
  }

  return (
    <TextInputComponent
      label="Asuransi Pengiriman"
      value={formatPrice(insuranceFee ?? 0)}
      rightSection={generateLoader()}
      disabled
    />
  );
}
