import { Select } from "@mantine/core";
import { useFormProvider } from "../../../../providers";
import { CourierServiceModel } from "models";

export function CourierServiceComponent() {
  const { courierService } = useFormProvider();

  function generateValue(courierService?: CourierServiceModel) {
    if (!courierService) return "";
    return courierService.id;
  }

  function generateData(courierService?: CourierServiceModel) {
    if (!courierService) return [];
    return [
      {
        label: courierService.name,
        value: courierService.id,
      },
    ];
  }

  return (
    <Select
      label="Layanan Kurir"
      value={generateValue(courierService)}
      data={generateData(courierService)}
      disabled
    />
  );
}
