import React from "react";
import { TextInput } from "@mantine/core";
import { useLoginProvider } from "../../providers";

export function EmailInputComponent() {
  const { email, setEmail } = useLoginProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <TextInput
      label="Email"
      placeholder="email@example.com"
      value={email}
      onChange={handleChange}
    />
  );
}
