import { CourierServiceModel } from "models/courier-service";
import { Properties, Constructor } from "./types";

export interface DeliveryFeeModel extends Properties {}
export class DeliveryFeeModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    return new DeliveryFeeModel({
      serviceCode: res.service_code,
      price: res.price,
      courierServiceId: res.courier_service_id,
      courierService: CourierServiceModel.fromResponse(res.courier_service),
    });
  }
}
