import { Button } from "@mantine/core";
import { useCallback, useState } from "react";
import {
  useFormProvider,
  useDeliveryDestinationProvider,
  useStepProvider,
} from "../../../../providers";

export function SubmitButtonComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const { setServiceCenter } = useFormProvider();
  const { serviceCenter } = useDeliveryDestinationProvider();
  const { nextStep } = useStepProvider();

  const ableToNextStep = useCallback(() => {
    if (!serviceCenter) return false;
    return true;
  }, [serviceCenter]);

  async function handleClick() {
    try {
      setIsLoading(true);
      if (!ableToNextStep()) {
        const errMessage = `Service center is not selected`;
        throw new Error(errMessage);
      }

      setServiceCenter(serviceCenter);

      // Use artificial loading to simulate the process
      const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));
      await delay();

      nextStep();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      onClick={handleClick}
      disabled={!ableToNextStep()}
      loading={isLoading}
    >
      Pilih Service Center Tujuan
    </Button>
  );
}
