import { Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "tabler-icons-react";

export function BackButtonComponent() {
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <Group>
      <Button leftIcon={<ChevronLeft />} variant="light" onClick={handleClick}>
        Back
      </Button>
    </Group>
  );
}
