import { MantineThemeOverride } from "@mantine/core";

export const globalTheme: MantineThemeOverride = {
  colors: {
    primary: [
      "#E8E0E0",
      "#D8C7C6",
      "#CBAEAD",
      "#C39493",
      "#BF7A78",
      "#C05E5B",
      "#C73E3A",
      "#A74340",
      "#8D4543",
      "#784443",
    ],
    lightgray: [
      "#FDFDFD",
      "#FAFAFB",
      "#F8F8F9",
      "#F5F5F7",
      "#F1F0F2",
      "#ECEBEE",
      "#E7E6EA",
      "#C9C7CD",
      "#ABA9B1",
      "#8C8A94",
    ],
    darkred: [
      "#F9ECEC",
      "#F3D9D9",
      "#ECC6C6",
      "#E6B3B3",
      "#DA8C8C",
      "#CD6666",
      "#C14040",
      "#9A3333",
      "#742626",
      "#4D1A1A",
    ],
    svgStroke: ["#A645CF", "#4564CF", "#45CF9D"],
    svgBackground: ["#F3ECF6", "#ECEFF6", "#ECF6ED"],
    badgeColor: [
      "#C14040",
      "#32C375",
      "#FF8B01",
      "#6932C3",
      "#2486E0",
      "#24BEE0",
    ],
    badgeBackground: [
      "#F9D3D3",
      "#EEFFF6",
      "#FFE8CC",
      "#FCEEFF",
      "#D3D7F9",
      "#D3F2F9",
    ],
    hoverColor: ["#b4b4b440"],
  },
  spacing: { xl: 24 },
  primaryColor: "primary",
  fontFamily: "Montserrat",
  headings: {
    sizes: {
      h1: {
        fontSize: "32px",
        lineHeight: "48px",
      },
    },
  },
};
