import {
  SerialNumberComponent,
  DeviceComponent,
  BrandComponent,
  PartsComponent,
  ComplaintComponent,
  ServiceCenterComponent,
  ServiceCenterAddressComponent,
  NameComponent,
  PhoneNumberComponent,
  PickupLocationComponent,
  AddressComponent,
  CourierComponent,
  CourierServiceComponent,
  DeliveryFeeComponent,
  SubmitButtonComponent,
  InsuranceFeeComponent,
} from "./components";

export function ReviewForm() {
  return <></>;
}

ReviewForm.SerialNumber = SerialNumberComponent;
ReviewForm.Device = DeviceComponent;
ReviewForm.Brand = BrandComponent;
ReviewForm.Parts = PartsComponent;
ReviewForm.Complaint = ComplaintComponent;
ReviewForm.ServiceCenter = ServiceCenterComponent;
ReviewForm.ServiceCenterAddress = ServiceCenterAddressComponent;
ReviewForm.Name = NameComponent;
ReviewForm.PhoneNumber = PhoneNumberComponent;
ReviewForm.PickupLocation = PickupLocationComponent;
ReviewForm.Address = AddressComponent;
ReviewForm.Courier = CourierComponent;
ReviewForm.CourierService = CourierServiceComponent;
ReviewForm.DeliveryFee = DeliveryFeeComponent;
ReviewForm.SubmitButton = SubmitButtonComponent;
ReviewForm.InsuranceFee = InsuranceFeeComponent;
