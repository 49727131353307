import { Stack, Text } from "@mantine/core";
import { PaymentInformationForm } from "../PaymentInformationForm";

export function PaymentInformationStep() {
  return (
    <Stack>
      <Text size="lg" fw={600}>
        Lakukan Pembayaran
      </Text>
      <Text>
        Silahkan lakukan pembayaran dengan cara menekan tombol dibawah ini atau
        dengan mengunjungi laman berikut.
      </Text>
      <Text c="dimmed">
        Mohon diperhatikan, Mazuta Group tidak akan melakukan pengambilan barang
        jika pembayaran belum diterima.
      </Text>
      <PaymentInformationForm.PickupRequest />
      <PaymentInformationForm.PaymentLink />
      <PaymentInformationForm.PayButton />
    </Stack>
  );
}
