import { FormInput } from "components";

import { useDeviceDeliveryInformations, useSerialNumber } from "hooks";
import { useFormProvider } from "../../../../providers";

export function DeliveryFeeComponent() {
  const { serviceCenter, courierService, originLocation, serialNumber } =
    useFormProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { deviceDeliveryInformations } = useDeviceDeliveryInformations({
    deviceId: mSerialNumber?.device.id,
  });

  return (
    <FormInput.CourierDeliveryFee
      originId={originLocation?.id}
      destinationId={serviceCenter?.logisticLocation.destinationId}
      weight={deviceDeliveryInformations[0]?.actualWeight}
      serviceId={courierService?.id}
      disabled
    />
  );
}
