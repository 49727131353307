import { FormInput } from "components";
import { useFormProvider } from "../../../../providers";
import { useDeviceDeliveryInformations, useSerialNumber } from "hooks";

export function InsuranceFeeComponent() {
  const { serialNumber, courier } = useFormProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { deviceDeliveryInformations } = useDeviceDeliveryInformations({
    deviceId: mSerialNumber?.device.id,
  });

  const retrieveInsuredValue = () => {
    if (!deviceDeliveryInformations) return 0;
    if (deviceDeliveryInformations.length === 0) return 0;
    return deviceDeliveryInformations[0]?.insuredValue;
  };

  return (
    <FormInput.CourierInsuranceFee
      courier={courier}
      insuredValue={retrieveInsuredValue()}
    />
  );
}
