import { FormInput } from "components";

import { useDeviceDeliveryInformations, useSerialNumber } from "hooks";
import {
  useCourierInformationProvider,
  useFormProvider,
} from "../../../../providers";

export function DeliveryFeeComponent() {
  const { courierService } = useCourierInformationProvider();
  const { serviceCenter, originLocation, serialNumber } = useFormProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { deviceDeliveryInformations, isLoading } =
    useDeviceDeliveryInformations({
      deviceId: mSerialNumber?.device.id,
    });

  return (
    <FormInput.CourierDeliveryFee
      originId={originLocation?.id}
      destinationId={serviceCenter?.logisticLocation.destinationId}
      weight={deviceDeliveryInformations[0]?.actualWeight}
      serviceId={courierService?.id}
      isLoading={isLoading}
      disabled
    />
  );
}
