import React from "react";

import { useState } from "react";
import { useModals } from "@mantine/modals";

import { AlertOctagon } from "tabler-icons-react";
import { Alert, Box, Button, Space, Stack } from "@mantine/core";
import {
  BackButtonComponent,
  ModalComponent,
  TextInputComponent,
} from "components";

export function WarehouseTransactionPage() {
  const [strSn, setStrSn] = useState<string>("");
  const modals = useModals();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    modals.openContextModal("warehouse-transaction.confirmation", {
      title: <ModalComponent.Title>Konfirmasi</ModalComponent.Title>,
      innerProps: {
        serialNumber: strSn,
        onSubmit: () => setStrSn(""),
      },
    });
  }

  function handleSnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setStrSn(e.target.value);
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <BackButtonComponent />
        <Alert
          icon={<AlertOctagon />}
          title="Perhatian"
          color="red"
          variant="filled"
        >
          Hanya untuk transaksi <strong>KELUAR GUDANG</strong>. Jangan gunakan
          untuk penjualan atau pencatatan garansi.
        </Alert>
        <Space h="lg" />
        <TextInputComponent
          label="Serial Number"
          value={strSn}
          onChange={handleSnChange}
          autoFocus
        />
        <Button type="submit">Catat Transaksi Keluar</Button>
      </Stack>
    </Box>
  );
}
