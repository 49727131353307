import { Container, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { BrowserRouter } from "react-router-dom";
import { PublicRoute } from "routes";
import { AuthProvider } from "./providers";
import { globalTheme } from "./styles/global";
import { ModalsProvider } from "@mantine/modals";
import { WarehouseTransactionConfirmationModal } from "modals";

export function App() {
  return (
    <MantineProvider theme={globalTheme} withGlobalStyles withNormalizeCSS>
      <NotificationsProvider autoClose={4000} limit={8}>
        <AuthProvider>
          <BrowserRouter>
            <ModalsProvider
              modals={{
                "warehouse-transaction.confirmation":
                  WarehouseTransactionConfirmationModal,
              }}
            >
              <Container h="100vh" size="xs" p={0}>
                <PublicRoute />
              </Container>
            </ModalsProvider>
          </BrowserRouter>
        </AuthProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
}
