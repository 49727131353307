import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  wrapper: { position: "relative", height: "100%", backgroundColor: "#E9ECEF" },
  bg: {
    position: "absolute",
    height: "50%",
    backgroundColor: theme.colors[theme.primaryColor],
    borderBottomRightRadius: 32,
    borderBottomLeftRadius: 32,
    top: 0,
    left: 0,
    right: 0,
  },
  main: { display: "flex", justifyContent: "center", position: "relative" },
  logoWrapper: {
    width: "100%",
    height: "25%",
    display: "flex",
    paddingTop: theme.spacing.lg * 5,
    justifyContent: "center",
  },
}));
