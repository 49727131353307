import Config from "configs/api";
import axios from "axios";
import lodash from "lodash";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

export const net = async (token: string, headers?: Record<string, string>) => {
  const devHeader = Config.url.includes("localhost")
    ? { "X-Consumer-ID": "mazuta-dev" }
    : {};

  const isJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const generateCustomHeader = (token: string) => {
    const contentHeader = { "Content-Type": "application/json" };

    if (isJSON(token)) {
      return contentHeader;
    } else {
      return lodash(contentHeader)
        .merge({ Authorization: `Bearer ${token}` })
        .value();
    }
  };

  const generateParams = (token: string) => {
    if (isJSON(token)) {
      const jsonToken = JSON.parse(token);
      return { apikey: jsonToken.apiKey };
    } else return undefined;
  };

  const refreshAccessToken = async (token: string) => {
    if (isJSON(token)) return token;

    const refreshToken = window.localStorage.getItem("refresh_token");
    if (!refreshToken) return token;

    const strExpiredAt = window.localStorage.getItem("expired_at");
    if (!strExpiredAt) return token;

    const expiredAt = DateTime.fromISO(strExpiredAt);
    const now = DateTime.utc();
    if (now < expiredAt) return token;

    const url = "https://mazuta.jp.auth0.com/oauth/token";
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const data = new URLSearchParams();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", refreshToken);
    data.append("client_id", "WnHR2ValFTz6ZsnzozlyU8xsJRt7fBtD");
    data.append(
      "client_secret",
      "-K3tZeEKd8oN3mmDlUUQB_pQc--f_Y0Cw9KPOuE8yV9OqIeGkGzcEOjqaRh1Gtfz"
    );

    const res = await axios.post(url, data, { headers });
    const accessToken = res.data.access_token;
    const expiresIn = res.data.expires_in;
    const newExpiredAt = DateTime.utc().plus({ seconds: expiresIn });

    window.localStorage.setItem("access_token", accessToken);
    window.localStorage.setItem("expired_at", newExpiredAt.toISO());

    return accessToken;
  };

  const validToken = await refreshAccessToken(token);
  const customHeader = generateCustomHeader(validToken);
  const customParams = generateParams(validToken);
  const mergedHeaders = lodash(customHeader)
    .merge(headers ? headers : {})
    .merge(devHeader)
    .value();

  return axios.create({
    baseURL: Config.url,
    headers: mergedHeaders,
    params: customParams,
  });
};

/**
 * Return a data from response if no error_text
 * @param res
 * @returns
 */
export const responseChecker = (res: AxiosResponse<any, any>) => {
  const errorText = lodash(res.data).get("error_text", undefined);
  if (errorText === undefined) return res.data;
  else throw new Error(errorText);
};

export const fetcher = async ([pathUrl, token]: [string, string]) => {
  const netInstance = await net(token);
  const res = await netInstance.get(pathUrl);
  if (res.status !== 200) throw new Error(res.statusText);

  const errorText = lodash(res.data).get("error_text", undefined);
  if (errorText === undefined) return res.data;
  else throw new Error(errorText);
};

export const download = (url: string, fileName: string, ext: string) => {
  const link = document.createElement("a");
  const fullName = `${fileName}.${ext}`;
  link.href = url;
  link.setAttribute("download", fullName);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
