import { createContext, useContext, useState } from "react";
import { funcNotImplemented } from "utils/misc";
import { SparePartModel } from "models";
import {
  DeviceInformationContextProps,
  DeviceInformationProviderProps,
} from "./types";

const DeviceInformationContext = createContext<DeviceInformationContextProps>({
  serialNumber: "",
  parts: [],
  complaint: "",
  setSerialNumber: funcNotImplemented,
  setParts: funcNotImplemented,
  setComplaint: funcNotImplemented,
});

export function DeviceInformationProvider(
  props: DeviceInformationProviderProps
) {
  const { children } = props;

  const [serialNumber, setSerialNumber] = useState<string>("");
  const [parts, setParts] = useState<SparePartModel[]>([]);
  const [complaint, setComplaint] = useState<string>("");

  return (
    <DeviceInformationContext.Provider
      value={{
        serialNumber,
        parts,
        complaint,
        setSerialNumber,
        setParts,
        setComplaint,
      }}
    >
      {children}
    </DeviceInformationContext.Provider>
  );
}

export function useDeviceInformationProvider() {
  return useContext(DeviceInformationContext);
}
