import { Select } from "@mantine/core";
import { useOriginLocations } from "hooks";
import { useSenderInformationProvider } from "../../../../providers";
import { OriginLocationModel } from "models";

export function OriginLocationComponent() {
  const { originLocation, setOriginLocation } = useSenderInformationProvider();
  const { originLocations } = useOriginLocations();

  function generateData() {
    if (!originLocations) return [];
    return originLocations.map((location) => ({
      value: location.id,
      label: location.name,
    }));
  }

  function handleChange(value: string) {
    if (!originLocations) return;

    // We need to find originLocation from originLocations
    const filterFunc = (location: OriginLocationModel) => location.id === value;
    const originLocation = originLocations.find(filterFunc);
    setOriginLocation(originLocation);
  }

  return (
    <Select
      label="Kota Penjemputan"
      placeholder="eg. SURABAYA"
      data={generateData()}
      value={originLocation && originLocation.id}
      onChange={handleChange}
      nothingFound="Lokasi Tidak Ditemukan"
      searchable
    />
  );
}
