import lodash from "lodash";
import { UsePickupRequestInvoicesProps } from "./types";
import { InvoiceModel } from "models";

import useSWR from "swr";
import { useAuth } from "providers";
import { useEffect, useState } from "react";
import { fetcher } from "utils/net";

export function usePickupRequestInvoices(props: UsePickupRequestInvoicesProps) {
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);

  const { pickupRequestId } = props;
  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!pickupRequestId) return undefined;
    if (!accessToken) return undefined;

    const relUrl = `/pickup-requests/${pickupRequestId}/invoices`;
    return [relUrl, accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setInvoices([]);
      return;
    }

    const errorText = lodash(data).get("error_text");
    if (errorText) setInvoices([]);

    const invoices = data.data.map((item: Record<string, any>) =>
      InvoiceModel.fromResponse(item)
    );

    setInvoices(invoices);
  }, [data]);

  return { invoices: invoices, isLoading: isLoading, error: error };
}
