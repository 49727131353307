import { FormInput } from "components";

import { useDeviceDeliveryInformations, useSerialNumber } from "hooks";
import { useCallback } from "react";
import {
  useCourierInformationProvider,
  useFormProvider,
} from "../../../../providers";

export function InsuranceFeeComponent() {
  const { serialNumber } = useFormProvider();
  const { courier } = useCourierInformationProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { deviceDeliveryInformations } = useDeviceDeliveryInformations({
    deviceId: mSerialNumber?.device.id,
  });

  const retrieveInsuredValue = useCallback(() => {
    if (!deviceDeliveryInformations) return 0;
    if (deviceDeliveryInformations.length === 0) return 0;
    return deviceDeliveryInformations[0]?.insuredValue;
  }, [deviceDeliveryInformations]);

  return (
    <FormInput.CourierInsuranceFee
      courier={courier}
      insuredValue={retrieveInsuredValue()}
    />
  );
}
