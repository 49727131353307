import { Stack } from "@mantine/core";
import { CourierInformationForm } from "..";
import { CourierInformationProvider } from "../../providers";
import { BackButtonComponent } from "..";

export function CourierInformationStep() {
  return (
    <CourierInformationProvider>
      <Stack>
        <CourierInformationForm.SelectCourier />
        <CourierInformationForm.SelectCourierService />
        <CourierInformationForm.DeliveryFee />
        <CourierInformationForm.InsuranceFee />
        <BackButtonComponent />
        <CourierInformationForm.SubmitButton />
      </Stack>
    </CourierInformationProvider>
  );
}
