import { DeviceInformationProvider } from "../../providers";
import { Stack, Text } from "@mantine/core";
import { DeviceInformationForm } from "..";

export function DeviceInformationStep() {
  return (
    <DeviceInformationProvider>
      <Stack>
        <DeviceInformationForm.SerialNumber />
        <Text fz="small" c="dimmed">
          Masukan serial number mesin utama yang akan diperbaiki.
        </Text>
        <DeviceInformationForm.Device />
        <DeviceInformationForm.Brand />
        <DeviceInformationForm.Parts />
        <DeviceInformationForm.Complaint />
        <DeviceInformationForm.SubmitButton />
      </Stack>
    </DeviceInformationProvider>
  );
}
