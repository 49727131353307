import useSWR from "swr";
import { useAuth } from "providers";
import { useState, useEffect } from "react";

import { fetcher } from "utils/net";
import { OriginLocationModel } from "models";

export function useOriginLocations() {
  const [originLocations, setOriginLocations] = useState<OriginLocationModel[]>(
    []
  );

  const { accessToken } = useAuth();
  const { data, error, isLoading } = useSWR(() => {
    if (!accessToken) return undefined;

    return ["/origin-locations?limit=9999", accessToken];
  }, fetcher);

  useEffect(() => {
    if (!data) {
      setOriginLocations([]);
      return;
    }

    const originLocations = data.data.map((res: Record<string, any>) => {
      return OriginLocationModel.fromResponse(res);
    });

    setOriginLocations(originLocations);
  }, [data]);

  return {
    originLocations: originLocations,
    error: error,
    isLoading: isLoading,
  };
}
