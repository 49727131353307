import {
  AddressComponent,
  SelectServiceCenterComponent,
  SubmitButtonComponent,
} from "./components";

export function DeliveryDestinationForm() {
  return <></>;
}

DeliveryDestinationForm.SelectServiceCenter = SelectServiceCenterComponent;
DeliveryDestinationForm.SubmitButton = SubmitButtonComponent;
DeliveryDestinationForm.Address = AddressComponent;
