import { Stack, Text } from "@mantine/core";

export function CompletedStep() {
  return (
    <Stack>
      <Text fz="lg" fw={600}>
        Selesai!
      </Text>
      <Text>
        Kami sudah menerima pembayaran kamu. Pesanan kamu akan segera kami
        &nbsp;
        <i>pickup</i>. Harap tunggu
      </Text>
      <Text>
        Jika terjadi kendala pada pesanan kamu, kami akan menghubungi kamu
        langsung pada nomor yang telah kamu daftarkan sebelumnya.
      </Text>
    </Stack>
  );
}
