import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export function WarehouseTransaction() {
  const navigate = useNavigate();

  function handleClick() {
    navigate("warehouse-transactions");
  }

  return (
    <Button variant="outline" onClick={handleClick}>
      Warehouse Transactions
    </Button>
  );
}
