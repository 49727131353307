import { OriginLocationModel } from "models";
import { createContext, useContext, useState } from "react";
import { funcNotImplemented } from "utils/misc";
import {
  SenderInformationContextProps,
  SenderInformationProviderProps,
} from "./types";

const SenderInformationContext = createContext<SenderInformationContextProps>({
  name: "",
  phoneNumber: "",
  originLocation: undefined,
  address: "",
  setName: funcNotImplemented,
  setPhoneNumber: funcNotImplemented,
  setOriginLocation: funcNotImplemented,
  setAddress: funcNotImplemented,
});

export function SenderInformationProvider(
  props: SenderInformationProviderProps
) {
  const { children } = props;

  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [originLocation, setOriginLocation] = useState<OriginLocationModel>();
  const [address, setAddress] = useState<string>("");

  return (
    <SenderInformationContext.Provider
      value={{
        name,
        phoneNumber,
        originLocation,
        address,
        setName,
        setPhoneNumber,
        setOriginLocation,
        setAddress,
      }}
    >
      {children}
    </SenderInformationContext.Provider>
  );
}

export function useSenderInformationProvider() {
  return useContext(SenderInformationContext);
}
