import { Properties, Constructor, InvoiceItem } from "./types";
import { SerialNumberModel } from "models/serial-number";
import { PaymentLinkModel } from "models/payment-link";
import { DateTime } from "luxon";

export interface InvoiceModel extends Properties {}
export class InvoiceModel implements Properties {
  constructor(args: Constructor) {
    Object.assign(this, args);
  }

  static fromResponse(res: Record<string, any>) {
    const parseItems = (items: Record<string, any>[]): InvoiceItem[] => {
      return items.map((item) => ({
        itemId: item.item_id,
        itemDescription: item.item_description,
        qty: parseInt(item.qty),
        price: parseInt(item.price),
      }));
    };

    return new InvoiceModel({
      id: res.id,
      source: {
        type: res.source.type,
        id: res.source.id,
      },
      serialNumberId: res.serial_number_id,
      serialNumber: SerialNumberModel.fromResponse(res.serial_number),
      paymentLinkId: res.payment_link_id,
      paymentLink: PaymentLinkModel.fromResponse(res.payment_link),
      items: parseItems(res.items),
      total: parseInt(res.total),
      createdAt: DateTime.fromISO(res.created_at),
      updatedAt: DateTime.fromISO(res.updated_at),
      deletedAt: res.deleted_at && DateTime.fromISO(res.deleted_at),
    });
  }
}
