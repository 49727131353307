import {
  OriginLocationComponent,
  NameComponent,
  PhoneNumberComponent,
  AddressComponent,
  SubmitButtonComponent,
} from "./components";

export function SenderInformationForm() {
  return <> </>;
}

SenderInformationForm.OriginLocation = OriginLocationComponent;
SenderInformationForm.Name = NameComponent;
SenderInformationForm.PhoneNumber = PhoneNumberComponent;
SenderInformationForm.Address = AddressComponent;
SenderInformationForm.SubmitButton = SubmitButtonComponent;
