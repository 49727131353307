import { createContext } from "react";
import { funcNotImplemented, funcNotImplementedAsync } from "utils/misc";
import { ContextProperties } from "./types";

export const AuthContext = createContext<ContextProperties>({
  accessToken: undefined,
  isFetching: false,
  login: funcNotImplementedAsync,
  apiKeyLogin: funcNotImplemented,
  logout: funcNotImplemented,
});
