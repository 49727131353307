import { CancelButtonComponentProps } from "./types";
import { Button } from "@mantine/core";

export function CancelButtonComponent(props: CancelButtonComponentProps) {
  const { id, context, disabled } = props;

  function handleClick() {
    context.closeModal(id);
  }

  return (
    <Button
      {...props}
      disabled={disabled}
      variant="outline"
      onClick={handleClick}
    >
      Cancel
    </Button>
  );
}
