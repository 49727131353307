import { LoadingOverlay } from "@mantine/core";
import { useAuth } from "providers";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function LogoutPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    navigate("/login", { replace: true });
  }, [logout, navigate]);

  return <LoadingOverlay visible />;
}
