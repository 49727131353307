import React from "react";
import { TextInputComponent } from "components";
import { useFormProvider } from "../../../providers";

export function SerialNumberInputComponent() {
  const { data, dispatch } = useFormProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: "SET_DATA", key: "serialNumber", value: e.target.value });
  }

  return (
    <TextInputComponent
      label="Serial Number"
      value={data.serialNumber}
      onChange={handleChange}
    />
  );
}
