import { Stack } from "@mantine/core";
import { BackButtonComponent, SenderInformationForm } from "..";
import { SenderInformationProvider } from "../../providers";

export function SenderInformationStep() {
  return (
    <SenderInformationProvider>
      <Stack>
        <SenderInformationForm.Name />
        <SenderInformationForm.PhoneNumber />
        <SenderInformationForm.OriginLocation />
        <SenderInformationForm.Address />
        <BackButtonComponent />
        <SenderInformationForm.SubmitButton />
      </Stack>
    </SenderInformationProvider>
  );
}
