import { Stack } from "@mantine/core";
import { BackButtonComponent, DeliveryDestinationForm } from "..";
import { DeliveryDestinationProvider } from "../../providers";

export function DeliveryDestinationStep() {
  return (
    <DeliveryDestinationProvider>
      <Stack>
        <DeliveryDestinationForm.SelectServiceCenter />
        <DeliveryDestinationForm.Address />
        <BackButtonComponent />
        <DeliveryDestinationForm.SubmitButton />
      </Stack>
    </DeliveryDestinationProvider>
  );
}
