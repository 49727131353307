import { Select } from "@mantine/core";

import {
  useCourierInformationProvider,
  useFormProvider,
} from "../../../../providers";

import {
  useCourierServices,
  useDeviceDeliveryInformations,
  useSerialNumber,
} from "hooks";

export function SelectCourierServiceComponent() {
  const { courier, courierService, setCourierService } =
    useCourierInformationProvider();

  const { serialNumber } = useFormProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { deviceDeliveryInformations } = useDeviceDeliveryInformations({
    deviceId: mSerialNumber?.device.id,
  });

  const { courierServices } = useCourierServices({
    courierId: courier?.id,
    weight: deviceDeliveryInformations[0]?.actualWeight,
  });

  function generateData() {
    if (!courierServices) return [];

    return courierServices.map((c) => ({
      value: c.id,
      label: c.name,
    }));
  }

  function handleChange(value: string) {
    // We want to find the courier service based on the value
    const courierService = courierServices?.find((c) => c.id === value);

    // Set it to local state which is useCourierInformationProvider
    setCourierService(courierService);
  }

  return (
    <Select
      label="Jenis Layanan"
      placeholder="Pilih jenis layanan"
      value={courierService?.id}
      onChange={handleChange}
      data={generateData()}
    />
  );
}
