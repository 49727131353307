import { Button } from "@mantine/core";
import { useState } from "react";
import { useFormProvider, useStepProvider } from "../../../../providers";
import { useSerialNumber } from "hooks";
import { useAuth } from "providers";
import { net } from "utils/net";
import { PickupRequestModel } from "models";

export function SubmitButtonComponent() {
  const {
    serialNumber,
    parts,
    complaint,
    serviceCenter,
    name,
    phoneNumber,
    originLocation,
    address,
    courier,
    courierService,
    setPickupRequest,
  } = useFormProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { accessToken } = useAuth();
  const { nextStep } = useStepProvider();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit() {
    try {
      setIsLoading(true);
      if (!accessToken) {
        const errMessage = `Access token not found`;
        throw new Error(errMessage);
      }

      if (!mSerialNumber) {
        const errMessage = `Serial number ${serialNumber} not found`;
        throw new Error(errMessage);
      }

      if (!serviceCenter) {
        const errMessage = `Service center not found`;
        throw new Error(errMessage);
      }

      if (!originLocation) {
        const errMessage = `Origin location not found`;
        throw new Error(errMessage);
      }

      if (!courier) {
        const errMessage = `Courier not found`;
        throw new Error(errMessage);
      }

      if (!courierService) {
        const errMessage = `Courier service not found`;
        throw new Error(errMessage);
      }

      const data = {
        devices: [
          {
            serial_number_id: mSerialNumber.id,
            part_ids: parts.map((part) => part.id),
          },
        ],
        complaint: complaint,
        service_center_id: serviceCenter.id,
        sender_information: {
          name: name,
          phone_number: `+62${phoneNumber}`,
          pickup_location_id: originLocation.id,
          address: address,
        },
        courier_information: {
          courier_id: courier.id,
          courier_service_id: courierService.id,
        },
      };

      const netInstance = await net(accessToken);
      const resp = await netInstance.post("/pickup-requests", data);
      const pickupRequest = PickupRequestModel.fromResponse(resp.data);
      setPickupRequest(pickupRequest);

      // Artificial loading for a second
      const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));
      await delay();

      nextStep();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button onClick={handleSubmit} loading={isLoading}>
      Buat Permintaan Pickup
    </Button>
  );
}
