import React from "react";
import { PasswordInput } from "@mantine/core";
import { useLoginProvider } from "../../providers";

export function PassInputComponent() {
  const { password, setPassword } = useLoginProvider();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  return (
    <PasswordInput
      label="Password"
      placeholder="supersecretpassword"
      value={password}
      onChange={handleChange}
    />
  );
}
