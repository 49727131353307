import { Box, List, Stack, Text } from "@mantine/core";
import { FormProvider } from "./providers";
import { BackButtonComponent } from "components";
import { FormInputComponent, RegisterButtonComponent } from "./components";

export function WarrantyRegistrationPage() {
  return (
    <FormProvider>
      <Stack>
        <BackButtonComponent />
        <Box>
          <Text fw={700} fz="lg">
            Registrasi Garansi
          </Text>
          <Text c="dimmed">
            Lakukan registrasi garansi pada halaman berikut. Ikuti
            langkah-langkah berikut untuk melakukan registrasi garansi.
          </Text>
        </Box>

        <List type="ordered" color="dimmed">
          <List.Item>Foto nota pembelian dari customer.</List.Item>
          <List.Item>Foto barcode serial number dari mesin.</List.Item>
          <List.Item>Foto mesin yang dibeli oleh customer.</List.Item>
          <List.Item>Ketik SN pada kolom Serial Number</List.Item>
        </List>

        <FormInputComponent.Invoice />
        <FormInputComponent.Barcode />
        <FormInputComponent.Device />
        <FormInputComponent.SerialNumber />
        <RegisterButtonComponent />
      </Stack>
    </FormProvider>
  );
}
