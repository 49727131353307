import {
  AppSelectionPage,
  AppsPage,
  LogoutPage,
  WarehouseTransactionPage,
  WarrantyRegistrationPage,
} from "pages";
import { Navigate } from "react-router-dom";

export const PrivateRoute = () => {
  return [
    { path: "/", element: <Navigate to="/apps" replace /> },
    { path: "logout", element: <LogoutPage /> },
    {
      path: "apps",
      element: <AppsPage />,
      children: [
        { path: "", element: <AppSelectionPage /> },
        {
          path: "warehouse-transactions",
          element: <WarehouseTransactionPage />,
        },
        {
          path: "warranty-registration",
          element: <WarrantyRegistrationPage />,
        },
      ],
    },
  ];
};
