import { Select } from "@mantine/core";

import {
  useFormProvider,
  useCourierInformationProvider,
} from "../../../../providers";

import {
  useDeviceDeliveryInformations,
  useSerialNumber,
  useCouriers,
} from "hooks";

export function SelectCourierComponent() {
  const { serialNumber } = useFormProvider();
  const { courier, setCourier } = useCourierInformationProvider();
  const { serialNumber: mSerialNumber } = useSerialNumber({ serialNumber });
  const { deviceDeliveryInformations } = useDeviceDeliveryInformations({
    deviceId: mSerialNumber?.device.id,
  });

  const { couriers } = useCouriers({
    weight: deviceDeliveryInformations[0]?.actualWeight,
  });

  function handleChange(value: string) {
    // We want to find the courier service based on the value
    const courier = couriers?.find((c) => c.id === value);

    // Set it to local state which is useCourierInformationProvider
    setCourier(courier);
  }

  function generateData() {
    if (!couriers) return [];

    return couriers.map((c) => ({
      value: c.id,
      label: c.name,
    }));
  }

  return (
    <Select
      label="Kurir"
      placeholder="Pilih kurir"
      data={generateData()}
      onChange={handleChange}
      value={courier?.id}
    />
  );
}
